<button routerLink="../../comunicaciones/nueva" class="botonUnide big mB30 block">CREAR NUEVA +</button>
<p class="grisClaro bold apartado">COMUNICACIONES ENVIADAS</p>
<span class="subrayado bgAzul mB20 mT10"></span>

 <div>
  <p *ngIf="cargando">Cargando...</p>
  <div class="contenedorComunicacion sombra mB20" *ngFor="let comunicacion of comunicaciones">
    <div class="border w40">
      <p class="pointer grisOscuro mB5 bold" [routerLink]="['../../comunicaciones/comunicacion',comunicacion.id_comunicacion]">{{comunicacion.titulo | truncate:40}}</p> 
      <p><span class="fCapitalize azul fechaEnvio">Enviado: {{comunicacion.fecha_creacion | fechaString}}</span>
        <span class="inlineBlock azul mediaIcono" *ngIf="comunicacion.tipo_comunicacion==1"><app-video></app-video></span>
        <span class="inlineBlock azul mediaIcono" *ngIf="comunicacion.tipo_comunicacion==0"><app-imagen></app-imagen></span>     
      </p>    
    </div><div class="w15 center">
      <p class="grisOscuro bold">{{comunicacion.num_enviados}}</p>
      <p class="grisClaro datoComunicacion">ENVIADOS</p>      
    </div><div class="w15 center">      
      <p class="grisOscuro bold">{{comunicacion.  num_comunicaciones_recibidas}}</p>
      <p class="grisClaro datoComunicacion">RECIBIDOS</p>
    </div><div class="w15 center">      
      <p class="grisOscuro bold">{{comunicacion.num_leidas}}</p>
      <p class="grisClaro datoComunicacion">LEIDOS</p>
    </div><div class="w15 center">
      <p class="grisOscuro bold">{{comunicacion.ratio}}</p>
      <p class="grisClaro datoComunicacion">RATIO LEIDOS</p>
    </div><!-- <div class="w15 tRight">
       <app-eliminar (click)="eliminarComunicacion(comunicacion.id_comunicacion)" class="inlineBlock w28px grisClaro pointer"></app-eliminar>
    </div> -->
</div> 