<cabecera></cabecera>
<div *ngIf="aviso"  class="notificacion {{tipoAviso}}">{{aviso}}<span class="right pR20 pointer" (click)="closeAlert()"><app-close></app-close></span></div>

<div id="container" class="center">
  <div class="parteCentralForm mT100">
    <div class="imagenLogo mB40">
      <app-logo-azul></app-logo-azul>
    </div>
  <div *ngIf="!envioCorrecto">
    <p class="grisOscuro italic mB20 azul">¿Has olvidado tu contraseña?<br>
    Introduce aquí tu email y te mandaremos una nueva</p>
    
    <form (ngSubmit)="onSubmitChange()" #changeForm="ngForm"> 
      <div class="input_container mB40">  
        <input type="email" class="input mB20 block" placeholder="Escribe aquí tu email..." [(ngModel)]="email" name="email" required>   
        <input type="password" class="input mB20 block" placeholder="Escribe aquí tu contraseña..." [(ngModel)]="newPassword1" name="password1" validateEqual="password2" required reverse="true">   
        <input type="password" class="input mB20 block" placeholder="Repite tu contraseña..." [(ngModel)]="newPassword2" name="password2" validateEqual="password1" #password2="ngModel" required>  
      </div>
      <p class="mB20" [hidden]="password2.valid ||  (password2.pristine && !changeForm.submitted)">
          Las contraseñas deben de ser iguales
      </p>
      <button type="submit" class="botonUnide big block" [disabled]="!changeForm.form.valid">ENVIAR</button>
    </form>
  </div>
  <div *ngIf="envioCorrecto">
    Password modificada. Vuelve a la <a routerLink="/login">pantalla inicial</a> para poder acceder a la aplicación    
  </div>

</div>

<app-pie></app-pie>