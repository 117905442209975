<cabecera></cabecera>
<div *ngIf="aviso"  class="notificacion {{tipoAviso}}">{{aviso}}<span class="right pR20 pointer" (click)="closeAlert()"><app-close></app-close></span></div>

<div id="container" class="center">
  <div class="parteCentralForm mT100">
    <div class="imagenLogo mB40">
       <app-logo-azul></app-logo-azul> 
    </div>
    <div *ngIf="!envioCorrecto">
      <p class="grisOscuro italic mB20 azul">¿Has olvidado tu contraseña?<br>
      Introduce aquí tu email y te mandaremos una nueva</p>
      <form (ngSubmit)="onSubmitReset()" #resetForm="ngForm">  
        <div class="input_container mB40">  
          <input type="email" class="input" placeholder="Escribe aquí tu email..." name="administrador.email"  [(ngModel)]="administrador.email" required>  
          <app-sobre class="iconLogin grisClaro"></app-sobre>            
        </div>
        <button type="submit" class="botonUnide big block" [disabled]="!resetForm.form.valid">ENVIAR</button>
      </form>
    </div>
    <div *ngIf="envioCorrecto">
      {{aviso}}
    </div>
  </div>
</div>

<app-pie></app-pie>