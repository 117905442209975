<p *ngIf="cargando">Cargando...</p>
<div class="cabeceraSocios grisClaro bold apartado">  
  <app-usuarios class="inlineBlock w24px"></app-usuarios>  
  SOCIOS
  <button  class="botonUnide short" routerLink="../../socios/nuevo">
    NUEVO SOCIO
    <app-nuevo-socio class="inlineBlock w24px"></app-nuevo-socio>  
  </button>
</div>
<div *ngFor="let socio of socios">
  <div class="contenedorSocio">
    <span class="border">      
      <app-usuario class="inlineBlock w16px"></app-usuario>  
    </span> 
    <span class="bold grisOscuro pointer" [routerLink]="['../../socios/editar',socio.id_usuario]">{{socio.nombre | uppercase}}</span>    
    <div class="right edicionEmpleados">      
      <span *ngIf="socio.id_unico && socio.id_unico != 0 " class="azul pointer mR20 bold" (click)="desvincular(socio)">DESVINCULAR</span>
      <span class="azul pointer mR20 bold" [routerLink]="['../../socios/editar',socio.id_usuario]">EDITAR</span>
      <span *ngIf="socio.activo == 1" class="actividad azul bold pointer center inlineBlock" (click)="activarToogle(socio)">ACTIVO</span>  
      <span *ngIf="socio.activo == 0" class="actividad azul bold pointer center inlineBlock" (click)="activarToogle(socio)">DESACTIVO</span>      
    </div>
  </div><button  class="botonUnide big sin_borde_izquierdo" (click)="borrarSocio(socio)">BORRAR</button>
</div>