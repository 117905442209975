<app-nuevo-socio class="iconoSeccion opacity50 azul"></app-nuevo-socio>
<form (ngSubmit)="onSubmit()" #socioEditForm="ngForm" class="mB30">
  <div class="datosSocio mB30">
    <p class="mB15 grisClaro bold apartado"><app-nuevo-socio class="inlineBlock w24px mR5"></app-nuevo-socio>NUEVO SOCIO</p>    
    <input [(ngModel)]="socio.nombre" name="nombre" placeholder="Nombre" required type="text" class="mR10 grisClaro"><input [(ngModel)]="socio.email" name="email" 
    placeholder="Email" required type="email" class="grisClaro">
  </div>  

  <div class="datosTienda mB30">
    <p class="mB15 grisClaro bold apartado"><app-tienda class="inlineBlock w24px mR5"></app-tienda>TIENDAS</p>
    <select class="mR10 grisClaro" *ngFor="let filtro of filtros; let i = index" [(ngModel)]="opcionesFiltro[i].opcion" name="opcionesFiltro[i].opcion">
      <option selected value="0">{{filtro.titulo_filtro}}</option>
      <option *ngFor="let filtroSpecific of filtro.filtros" [value]="filtroSpecific.valor">{{filtroSpecific.titulo}}</option>      
    </select><input [(ngModel)]="busqueda" name="busqueda"  placeholder="Buscar..."  type="text" class="inputBuscar grisClaro"><button 
    type="button" class="botonUnide short sin_borde_izquierdo" (click)="getTiendas()">BUSCAR</button><span class="mL10" *ngIf="loading">Cargando...</span>
  </div>

  <div class="seleccionTiendas">
    <div class="resultados">
      <p class="mB15 grisClaro bold apartado">RESULTADOS<span class="right normal">Añadir todos<app-add class="inlineBlock vAlignM w24px mL20" (click)="addAll()"></app-add></span></p>      
      <div class="tiendasFiltradas">
        <select multiple (change)="setSelectedFiltradas($event.target)">
            <option style="background:url('../../assets/tienda-azul.png') no-repeat 10px center;background-size: 20px 20px;" class="contenedorTienda" 
            *ngFor="let tienda of tiendas" [value]="tienda.StoreNo">{{tienda.Description}}</option>
        </select> 
      </div>
    </div><div class="flechas">
      <p (click)="addTiendasSocio()" class="mB10"><app-add></app-add></p>
      <p (click)="removeTiendasSocio()"><app-quitar></app-quitar></p>
    </div><div class="resultados">
      <p class="mB15 grisClaro bold apartado">SELECCIONADOS<span class="right normal">Quitar todos<app-quitar class="inlineBlock vAlignM w24px mL20" (click)="removeAll()"></app-quitar></span></p> 
      <div class="tiendasFiltradas">
        <select multiple (change)="setSelectedSocio($event.target)">
            <option style="background:url('../../assets/tienda-azul.png') no-repeat 10px center;background-size: 20px 20px;" class="contenedorTienda" 
            *ngFor="let tienda of tiendasDestino" [value]="tienda.StoreNo">{{tienda.Description}}</option>
        </select>        
      </div>
    </div>
  </div>

  <div class="mT10 right">
    <button *ngIf="crearSocio" type="button" class="botonUnide short sin_borde_derecho gris bgGrisClaro" routerLink="../../socios/lista">VOLVER</button>
    <button *ngIf="!crearSocio" type="button" class="botonUnide short sin_borde_derecho gris bgGrisClaro" routerLink="../../../socios/lista">VOLVER</button>
    <button type="submit" class="botonUnide short sin_borde_izquierdo" 
    [disabled]="!socioEditForm.form.valid || editandoSocio==true || tiendasDestino.length == 0" [ngClass]="{'disabled': !socioEditForm.form.valid || editandoSocio==true 
    || tiendasDestino.length == 0}">
    <span *ngIf="crearSocio">CREAR NUEVO</span><span *ngIf="!crearSocio">GUARDAR</span></button>

  </div>

</form>
