<div id="outer">
  <div id="container">
    <div class="parteCentralForm">

       <app-logo></app-logo> 

      <form (ngSubmit)="onSubmit()" #loginForm="ngForm">

        <div class="input_container mB30">
            <input type="email" class="input" placeholder="Usuario" name="email" [(ngModel)]="administrador.email" required>
            <app-usuario class="iconLogin"></app-usuario>            
        </div>
        <div class="input_container">
            <input type="password" class="input" placeholder="Contraseña" name="password" [(ngModel)]="administrador.password" required>
            <app-candado class="iconLogin"></app-candado>            
        </div>
        <a class="mB50" routerLink="/olvido_contrasena">¿has olvidado tu contraseña?</a>
        <button class="botonUnide big block" type="submit" [disabled]="!loginForm.form.valid">ENTRAR</button>
        <p class="mT30 blanco center" [hidden]="code==0">{{mensaje}}</p>

      </form>
    </div>
  </div>
</div>

 
