<p class="grisClaro bold apartado inlineBlock">NUEVA COMUNICACIÓN:</p> <span class="bold grisOscuro">{{comunicacion.titulo}}</span>
<span class="subrayado bgAzul mB20 mT10"></span>

<form (ngSubmit)="onSubmit()" #comunicationForm="ngForm">

  <input class="mB10 grisClaro italic" type="text" name="comunicacion.titulo" [(ngModel)]="comunicacion.titulo" placeholder="Escriba aquí su título..." required> 
  <textarea id="textarea" name="comunicacion.contenido" placeholder="Escriba aquí su contenido..." required></textarea>

  <div class="mT40 mB40 grisClaro">
    <p class="mB10 mT15 gris italic">Seleccione una categoria</p>
    <select class="mR10 grisClaro categoria" name="comunicacion.categoria" [(ngModel)]="comunicacion.categoria" required>
      <option value="" [selected]="true">Categorias</option>
      <option value="Presidencia">Presidencia</option>
      <option value="Dpto. Relaciones Sociales">Dpto. Relaciones Sociales</option>
      <option value="Dpto. de Ventas y Marketing">Dpto. de Ventas y Marketing</option>
      <option value="Dpto. de Calidad">Dpto. de Calidad</option>
      <option value="Dpto. Informática">Dpto. Informática</option>
      <option value="Dpto. Administración">Dpto. Administración</option>
      <option value="Dpto. Logística">Dpto. Logística</option>
      <option value="Frescos">Frescos</option>      
    </select> 

    <p class="inlineBlock vAlignM mT20"><input type="checkbox" name="comunicacion.urgente" [(ngModel)]="comunicacion.urgente"><app-urgente class="w8px inlineBlock mR10 mL10"></app-urgente>Urgente (ademas de enviar la comunicación, se enviará una alerta al teléfono movil donde se haya/n logado el/los socio/s)</p>
    
  </div>  

  <div class="mB40">
    <app-imagen class="inlineBlock w24px grisClaro mR10"></app-imagen><app-video class="inlineBlock grisClaro w24px mR10"></app-video>
    <span class="italic grisClaro">
      <input type="radio" name="tipoMedia" value="0"  id="medioAdjunto" [checked]="tipoEnvioMedia===0"  (click)="changeTipoEnvioMedia(0)">
      <label class="pointer" for="medioAdjunto">Seleccione una imagen, video o fichero para adjuntar</label>
    </span>
    <span class="italic grisClaro mL30">
      <input type="radio" name="tipoMedia" value="1"  id="medioUrl" [checked]="tipoEnvioMedia===1" (click)="changeTipoEnvioMedia(1)">
      <label class="pointer" for="medioUrl">Escriba una url para imagen o video</label>      
    </span>
  </div>

  <div *ngIf="tipoEnvioMedia == 0" class="mB40">  
    <input type="file" (change)="fileChangeEvent($event)" class="inputfile" placeholder="Upload file..." id="file" />
    <label class="grisClaro mB5 italic botonUnide short" for="file">Examinar...</label>
    <p class="italic grisClaro">{{fileName}}</p>  
    <img class="w300" [src]="url">
  </div>

  <div *ngIf="tipoEnvioMedia==1" class="inlineBlock mB40 w400px"> 
    <input class="grisClaro italic" type="text" name="comunicacion.urlMedia" [(ngModel)]="comunicacion.urlMedia" placeholder="Escriba aquí la url...">
  </div>   

  <div class="datosTienda mB30">
    <p class="mB15 grisClaro bold apartado"><app-tienda class="inlineBlock w24px mR5"></app-tienda>USUARIOS</p>    
    <select class="mR10 grisClaro" *ngFor="let filtro of filtros; let i = index" [(ngModel)]="opcionesFiltro[i].opcion" name="opcionesFiltro[i].opcion">
      <option  selected value="0">{{filtro.titulo_filtro}}</option>
      <option *ngFor="let filtroSpecific of filtro.filtros" [value]="filtroSpecific.valor">{{filtroSpecific.titulo}}</option>      
    </select>
    <select *ngIf="filtroGrupos" class="mR10 grisClaro" [(ngModel)]="opcionGrupo" name="opcionGrupo">
      <option  selected value="0">GRUPOS</option>
      <option *ngFor="let grupo of filtroGrupos.gruposfiltro" [value]="grupo.id_gruposfiltro">{{grupo.nombre_grupo}}</option>      
    </select>
    <input [(ngModel)]="busqueda" name="busqueda" placeholder="Buscar..." type="text" class="inputBuscar grisClaro"><button type="button"
    class="botonUnide short sin_borde_izquierdo" (click)="getUsuarios()">APLICAR</button><span class="mL10" *ngIf="loading">Cargando...</span>
  </div>

  <div class="seleccionTiendas">
    <div class="resultados">
      <p class="mB15 grisClaro bold apartado">RESULTADOS<span class="right normal">Añadir todos<app-add class="inlineBlock vAlignM w24px mL20" (click)="addAll()"></app-add></span></p>      
      <div class="tiendasFiltradas">
        <select multiple (change)="setSelectedFiltradas($event.target)">
            <option style="background:url('../../assets/user-azul.png') no-repeat 10px center;background-size: 20px 25px;" class="contenedorTienda" 
            *ngFor="let usuario of usuarios" [value]="usuario.id_usuario">{{usuario.nombre}}</option>
        </select> 
      </div>
    </div><div class="flechas">
      <p (click)="addUsuarios()" class="mB10"><app-add></app-add></p>
      <p (click)="removeUsuarios()"><app-quitar></app-quitar></p>
    </div><div class="resultados">
      <p class="mB15 grisClaro bold apartado">SELECCIONADOS<span class="right normal">Quitar todos<app-quitar class="inlineBlock vAlignM w24px mL20" (click)="removeAll()"></app-quitar></span></p> 
      <div class="tiendasFiltradas">
        <select multiple (change)="setSelectedSocio($event.target)">
            <option style="background:url('../../assets/user-azul.png') no-repeat 10px center;background-size: 20px 25px;" class="contenedorTienda" 
            *ngFor="let usuario of usuariosDestino" [value]="usuario.id_usuario">{{usuario.nombre}}</option>
        </select>        
      </div>
    </div>
  </div>
  <div class="right mT10">
    <p  *ngIf="grupoCreado" class="inlineBlock vAlignM mR20">Grupo creado</p><p  *ngIf="enviandoGrupo" class="inlineBlock vAlignM mR20">Creando...</p><input type="text" [(ngModel)]="nombre_grupo" name="nombre_grupo" placeholder="Grupo nuevo" class="grisClaro w250"><button type="button" class="botonUnide short sin_borde_izquierdo" (click)="crearGrupo()"
    [ngClass]="{'disabled': usuariosDestino.length == 0 || enviandoGrupo}">CREAR</button>
  </div>

  <div class="tRight mT80">
    <button class="botonUnide short inlineBlock bgGrisClaro buttonForm mR20 sin_borde_derecho" type="submit">CANCELAR <span><app-close class="blanco"></app-close></span>
    </button><button class="botonUnide short inlineBlock buttonForm sin_borde_izquierdo" type="submit" 
    [disabled]="!comunicationForm.form.valid || usuariosDestino.length == 0" [ngClass]="{'disabled': !comunicationForm.form.valid || enviandoComunicacion==true 
    || usuariosDestino.length == 0}">ENVIAR 
    <span><app-forward  class="blanco"></app-forward></span></button>
    <p *ngIf="enviandoComunicacion" class="enviando">Enviando...</p>
  </div>  
</form>
