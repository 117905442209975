<cabecera></cabecera>
<div class="contenido">
<h3>CONDICIONES DE USO</h3>

<p>I.- Los t&eacute;rminos y condiciones que a continuaci&oacute;n se expresan vinculan a UNION DETALLISTAS ESPA&Ntilde;OLES S. COOP. UNIDE, con CIF F-28154946 y domicilio en Madrid, U.A. Mercamadrid, calle 21, parcela A-2, (en lo sucesivo &ldquo;UNIDE&rdquo;) y al SOCIO cooperativista (en lo sucesivo &ldquo;USUARIO&rdquo;), quien marcando la ventana &ldquo;Acepto&rdquo; manifiesta su expresa conformidad y se compromete a cumplirlos y hacerlos cumplir a quien de &eacute;l dependa. <br/><br/></p>
		<p>II.- UNIDE ha desarrollado una aplicaci&oacute;n m&oacute;vil (en lo sucesivo &ldquo;Aplicaci&oacute;n App UNIDE&rdquo;) para ser ejecutada en tel&eacute;fonos inteligentes, tabletas y otros dispositivos m&oacute;viles y que permite al USUARIO acceder a la Aplicaci&oacute;n Inform&aacute;tica UNIDEges de gesti&oacute;n de sus puntos de venta, as&iacute; como recibir y acusar recibo e incluso, en su caso, responder, a los mensajes remitidos por UNIDE.<br/><br/></p>
		<p>III.- La condici&oacute;n de USUARIO queda reservada a quien ostenta la condici&oacute;n de SOCIO de cooperativa UNIDE. En consecuencia, el derecho de uso de la Aplicaci&oacute;n app UNIDE se mantendr&aacute; vigente en tanto este mantenga tal condici&oacute;n, en plenitud de derechos y obligaciones y no se produzca ninguno de los supuestos de suspensi&oacute;n de derechos o de resoluci&oacute;n del contrato que vincula a UNIDE con el USUARIO en cualquiera de las l&iacute;neas de comercio o ense&ntilde;as de UNIDE que lleven impl&iacute;cita la revocaci&oacute;n autom&aacute;tica de la licencia de uso de acuerdo con el punto XII siguiente.<br/><br/></p>
		<p>IV.-  La autorizaci&oacute;n que por este contrato se concede es intransferible y no es exclusiva. Es decir, UNIDE podr&aacute; conceder autorizaci&oacute;n de uso a otros y EL USUARIO no podr&aacute; ceder o sublicenciar a su vez la Aplicaci&oacute;n app UNIDE ni de ninguna otra manera permitir su uso por un tercero. 
		V.- La autorizaci&oacute;n se hace extensiva a cuantas actualizaciones, mejoras y nuevos desarrollos pudieran realizarse en el futuro sobre la Aplicaci&oacute;n app UNIDE.<br/><br/></p>
		<p>VI.- El USUARIO necesitar&aacute; hardware y software compatibles y acceso a Internet. El rendimiento de la Aplicaci&oacute;n App UNIDE puede verse afectada por estos factores.<br/><br/></p>
		<p>VII.- El derecho de uso de la Aplicaci&oacute;n app UNIDE lleva aparejado, en su caso, la aportaci&oacute;n de los soportes en los que la aplicaci&oacute;n est&eacute; incorporada junto con los manuales e instrucciones de funcionamiento.<br/><br/></p>
		<p>VIII.- El USUARIO se obliga a utilizar la aplicaci&oacute;n m&oacute;vil App UNIDE conforme a la configuraci&oacute;n y funcionalidades autorizadas en cada momento por UNIDE, no pudiendo descompilar o de ninguna otra manera reprogramarla. <br/><br/></p>
		<p>IX.- UNIDE proporcionar&aacute; al USUARIO una contrase&ntilde;a &uacute;nica y exclusiva para su uso particular en dispositivo m&oacute;vil de su propiedad y bajo su control.<br/><br/></p>
		<p>X.-EL USUARIO se obliga a utilizar la Aplicaci&oacute;n app UNIDE cumpliendo en todo momento la legalidad vigente. Consecuentemente, EL USUARIO se hace responsable de la utilidad y uso que se d&eacute; a la aplicaci&oacute;n Inform&aacute;tica objeto de licencia, respondiendo en exclusiva de cualquier infracci&oacute;n o responsabilidad de todo tipo que se origine o devengue por el uso, manejo y utilizaci&oacute;n de la misma, quedando UNIDE exonerada totalmente de responsabilidad. <br/><br/></p>
		<p>XI.- A salvo la autorizaci&oacute;n que por el presente contrato se otorga, el USUARIO reconoce que no adquiere ning&uacute;n otro derecho sobre la Aplicaci&oacute;n app UNIDE y se obliga a no reclamar ning&uacute;n derecho, titulo o inter&eacute;s sobre el mismo<br/><br/></p>
		<p>XII.- La autorizaci&oacute;n que por este contrato se otorga quedar&aacute; autom&aacute;ticamente revocada y sin efecto legal alguno en cualquiera de los siguientes supuestos:<br/><br/></p>
		<p>a)	El incumplimiento de cualquiera de los presentes t&eacute;rminos y condiciones. <br/><br/></p>
		<p>b)	La sustituci&oacute;n de la Aplicaci&oacute;n App UNIDE por otra distinta.<br/><br/></p>
		<p>c)	La incorporaci&oacute;n del USUARIO a un Operador u Organizaci&oacute;n competidora de UNIDE o el anuncio por el USUARIO de su baja de cooperativa UNIDE.<br/><br/></p>
		<p>d)	El cierre, cesi&oacute;n o traspaso del establecimiento o establecimientos del USUARIO en el/los que estuviera ejerciendo el comercio. <br/><br/></p>
		<p>e)	En el momento en que UNIDE comunique a EL SOCIO la suspensi&oacute;n del derecho a su uso o la denuncia del contrato de ense&ntilde;a que les vincula.<br/><br/></p>
		<p>La revocaci&oacute;n causar&aacute; efecto autom&aacute;tico en todos los casos sin necesidad de preaviso y sin derecho a compensaci&oacute;n econ&oacute;mica alguna a favor del USUARIO.<br/><br/></p>
		<p>XIII.- A los efectos anteriores, UNIDE practicar&aacute; las notificaciones que correspondan en el domicilio que figure en la solicitud de alta como socio de cooperativa UNIDE, o en aquel que posteriormente &eacute;ste hubiera comunicado a la cooperativa. Si intentada una notificaci&oacute;n al socio, fuese imposible practicarla en el lugar prevenido en los p&aacute;rrafos anteriores, se entender&aacute; realizada mediante la colocaci&oacute;n de la comunicaci&oacute;n correspondiente en el tabl&oacute;n de anuncios del domicilio social y del centro de distribuci&oacute;n al que estuviera adscrito, as&iacute; como en la p&aacute;gina web de la sociedad. La notificaci&oacute;n practicada en el Tabl&oacute;n de Anuncios y en la p&aacute;gina web de la sociedad producir&aacute; plenos efectos jur&iacute;dicos en el plazo de quince d&iacute;as naturales <br/><br/></p>
		<p>XIV.- Notificada la revocaci&oacute;n de la licencia de uso de la Aplicaci&oacute;n App UNIDE, se producir&aacute; la desconexi&oacute;n inmediata con UNIDE, resultando inoperativa a partir de dicho momento, estando obligado a la devoluci&oacute;n inmediata de los soportes en los que la aplicaci&oacute;n estuviera incorporada, junto con los manuales y copias de seguridad de la misma que, en su caso, tuviera en su poder. As&iacute; mismo el USUARIO autoriza expresamente a que Cooperativa UNIDE, o la entidad que &eacute;sta libremente designe, acceda f&iacute;sica o telem&aacute;ticamente al hardware y elementos inform&aacute;ticos del USUARIO a fin de comprobar el cumplimiento de lo pactado en el p&aacute;rrafo anterior y en su defecto, y a cargo del USUARIO, se proceda al borrado o sustituci&oacute;n de la aplicaci&oacute;n<br/><br/></p>
		<p>XV.- La propiedad intelectual de la Aplicaci&oacute;n App UNIDE corresponde a UNIDE. El USUARIO, en los t&eacute;rminos y condiciones de este contrato, solo est&aacute; autorizado para su uso durante la vigencia del contrato. <br/><br/></p>
		<p>XVI.- Tanto el software como la base de datos contienen informaci&oacute;n y tecnolog&iacute;a considerada secreto industrial. En consecuencia, es confidencial y no debe ser revelado a ninguna tercera parte sin la previa autorizaci&oacute;n por escrito de UNIDE, a reserva en otro caso de las acciones legales, tanto civiles como penales, que pudieran corresponderle. <br/><br/></p>
		<p>XVII.- Las partes guardar&aacute;n absoluto secreto y confidencialidad sobre cuantos datos, informaci&oacute;n y documentaci&oacute;n hayan tenido acceso como consecuencia del cumplimiento del presente contrato, quedando expresamente prohibido trasladar a terceros ning&uacute;n soporte que contenga cualquier tipo de informaci&oacute;n ni siquiera para su conservaci&oacute;n, aun una vez finalizado el presente contrato. <br/><br/></p>
		<p>XVIII.- Est&aacute; estrictamente prohibido, y ser&aacute; cause que legitime a UNIDE para entablar las correspondientes acciones legales, tanto civiles como penales, en caso de aprovechamiento desleal por el USUARIO, o en beneficio de terceros competidores, de los datos, t&eacute;cnicas y procedimientos a los que el USUARIO hubiera accedido a trav&eacute;s o vali&eacute;ndose de la Aplicaci&oacute;n App UNIDE. <br/><br/></p>
		<p>XIX.- Es obligaci&oacute;n y derecho de EL USUARIO recibir la formaci&oacute;n necesaria para el correcto uso de la Aplicaci&oacute;n App UNIDE. Al fin expresado, UNIDE har&aacute; entrega al USUARIO de cuanta documentaci&oacute;n actual y futura fuera precisa para el adecuado uso y explotaci&oacute;n.<br/><br/></p>
		<p>XX.-  El USUARIO autoriza a UNIDE a recoger y utilizar los datos transmitidos a trav&eacute;s de la Aplicaci&oacute;n App UNIDE para mejorar sus productos y servicios, resultando autorizado a cederlos a empresas participadas y colaboradores.<br/><br/></p>
		<p>XXI.- Este contrato sustituye a cualquier otro convenio, antecedentes del mismo, negociaciones y cualesquiera otras comunicaciones previas mantenidas entre las partes, o una de ellas con un tercero, relacionadas con la materia de este contrato.  <br/><br/></p>
		<p>XXII.- Podr&aacute; ser objeto durante la vigencia de este contrato, de cualquier variaci&oacute;n del mismo con el fin de modificar, completar, sustituir o incluso suprimir el contenido de cualquiera de las estipulaciones contenidas en este documento, debiendo UNIDE comunicarlo a trav&eacute;s de la Aplicaci&oacute;n app UNIDE a fin de que el USUARIO acuse recibo en prueba de su expresa aceptaci&oacute;n.<br/><br/></p>
		<p>XXIII.- En el supuesto de que para la prestaci&oacute;n del servicio aqu&iacute; contratado UNIDE accediera a datos de car&aacute;cter personal de cuyo tratamiento sea responsable el USUARIO, con arreglo a lo previsto al efecto en la Ley Org&aacute;nica 15/1999, de 13 de diciembre y dem&aacute;s normativa aplicable, deber&aacute; UNIDE, con respecto a dichos datos:<br/><br/></p>
		<p>a)	Adoptar e implementar las medidas de &iacute;ndole t&eacute;cnica y organizativas que garanticen su seguridad y eviten su alteraci&oacute;n, p&eacute;rdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnolog&iacute;a, la naturaleza de los datos y los riesgos a que est&eacute;n expuestos, ya provengan de la acci&oacute;n humana o del medio f&iacute;sico o natural<br/><br/></p>
		<p>b)	Tratarlos conforme a las instrucciones del USUARIO. <br/><br/></p>
		<p>c)	Utilizarlos &uacute;nicamente en la medida en que sea necesario para la prestaci&oacute;n del servicio y, en ning&uacute;n caso, para un fin distinto al mencionado.<br/><br/></p>
		<p>d)	No cederlos, comunicarlos ni transmitirlos a terceros, ni siquiera para su conservaci&oacute;n<br/><br/></p>
		<p>e)	Asegurarse de que no quede a su disposici&oacute;n ninguno de ellos una vez que finalice la prestaci&oacute;n del servicio, para lo que deber&aacute; proceder a su destrucci&oacute;n o a devolv&eacute;rselos a UNIDE.<br/><br/></p>
		<p>Por su parte, el USUARIO se obliga, con respecto a los datos en cuesti&oacute;n, a:<br/><br/></p>
		<p>a)	Que todos ellos sean siempre leg&iacute;timos y a haberlos obtenido de forma l&iacute;cita.<br/><br/></p>
		<p>b)	Haber recabado el consentimiento pertinente a tal efecto de las personas a quienes vengan referidos los mismos.<br/><br/></p>
		<p>Con la inclusi&oacute;n de la presente estipulaci&oacute;n en este contrato las partes dan cumplimiento a lo ordenado en el art&iacute;culo 12 de la citada Ley Org&aacute;nica 15/1999. <br/><br/></p>
		<p>XXIV.- Para cuantas cuestiones litigiosas puedan plantearse, las partes, con renuncia expresa a su propio fuero, si lo tuvieran, se someten a la Jurisdicci&oacute;n y competencia de los Juzgados y Tribunales de Madrid capital.<br/><br/></p>
		
<app-pie></app-pie> 