<cabecera></cabecera>
<div *ngIf="aviso"  class="notificacion {{tipoAviso}}">{{aviso}}<span class="right pR20 pointer" (click)="closeAlert()"><app-close></app-close></span></div>

<div id="container" class="center">
  <div class="parteCentralForm mT100">
    <div class="imagenLogo mB40">
      <app-logo-azul></app-logo-azul>
    </div>
  <div *ngIf="!envioCorrecto">
    
    <p *ngIf="activo == '0'" class="grisOscuro italic mB20 azul">¿Has olvidado tu contraseña?<br>
    Dale a enviar y te la cambiaremos</p>
    <div *ngIf="activo == '1'">
      <p class="grisOscuro italic mB20 azul">Has activado la cuenta</p><p class="grisOscuro italic mB20 azul"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.unide.socio&hl=es">Descargate la app en play store</a>
      </p>
      <p class="grisOscuro italic mB20 azul">Dale a enviar y te enviaremos el código
      </p>
    </div>
    
    <form (ngSubmit)="onSubmitChange()" #changeForm="ngForm"> 
     <!--  <div class="input_container mB40">  
        <input type="email" class="input mB20 block" placeholder="Escribe aquí tu email..." [(ngModel)]="email" name="email" required>             
      </div>    -->   
      <button type="submit" class="botonUnide big block">ENVIAR</button>
    </form>
  </div>
  <div *ngIf="envioCorrecto">
    Te enviaremos un correo electrónico con el código.    
  </div>

</div>

<app-pie></app-pie>