<p *ngIf="cargando">Cargando...</p>
<div *ngIf="!cargando">
  <p class="azul semiBold mB5 fCapitalize">{{comunicacion.fecha_creacion | fechaString}}</p>
  <p class="grisOscuro bold mB30">{{comunicacion.titulo}}</p>
  <div class="mB30" [innerHTML]="sanitizer.bypassSecurityTrustHtml(comunicacion.contenido)"></div> 

  <!--Para Imagen de url o cargada-->
  <div *ngIf="comunicacion.tipo_comunicacion==0">
    <img class="mw100" src="{{comunicacion.url}}">
  </div>

  <!--Para video-->
  <div *ngIf="comunicacion.tipo_comunicacion==1">
    <!--Para video mp4-->
    <div *ngIf="extension=='.mp4'">
      <video width="320" height="240" controls>
        <source src="{{comunicacion.url}}" type="video/mp4">    
      </video>
    </div>
    <!--Para video tipo url-->
    <div *ngIf="extension!='.mp4'">
      <a class="rojo" href="{{comunicacion.url}}" target="blank">{{comunicacion.urlMedia}}</a>
    </div>
  </div>

  <div class="contenedorInfo mB30 mT30 bordesVerticales">
    <div class="border dotted">
      <app-check-simple class="inlineBlock w12px vAlignB grisClaro"></app-check-simple> 
      <div class="center">
        <p class="azul bold">{{comunicacion.num_enviados}}</p>
        <p class="grisClaro f14">ENVIADOS</p>      
      </div>
    </div><div class="border dotted">
      <app-check-doble class="inlineBlock w16px vAlignB grisClaro"></app-check-doble> 
      <div class="center">      
        <p class="azul bold">{{comunicacion.num_recibidas}}</p>
        <p class="grisClaro f14">RECIBIDOS</p>
      </div>
    </div><div class="border dotted">
      <app-check-doble class="inlineBlock w16px vAlignB azul"></app-check-doble> 
      <div class="center">      
        <p class="azul bold">{{comunicacion.num_leidas}}</p>
        <p class="grisClaro f14">LEIDOS</p>
      </div>
    </div><div> 
      <div class="center">
        <p class="azul bold">{{comunicacion.ratio}}</p>
        <p class="grisClaro f14">RATIO</p>
      </div>
    </div>
  </div> 

  <p class="italic griOscuro mB20">Enviada a:</p>

   <div>
    <div class="contenedorComunicacion sombra mB5" *ngFor="let usuario of comunicacion.usuarios">
      <span class="border">        
        <app-usuario  class="inlineBlock w16px grisClaro"></app-usuario>  
      </span>
      <span class="bold grisOscuro">{{usuario.nombre}}</span>      
      
      <div class="right">
        <app-check-simple *ngIf="usuario.estado_comunicacion==0" class="inlineBlock w20px vAlignB grisClaro"></app-check-simple>
        <app-check-doble *ngIf="usuario.estado_comunicacion==1" class="inlineBlock w20px vAlignB grisClaro"></app-check-doble>
        <app-check-doble *ngIf="usuario.estado_comunicacion==2" class="inlineBlock w20px vAlignB azul"></app-check-doble>
      </div>    

      <div class="right italic grisClaro" *ngIf="usuario.comunicacion_fecha!=null">
        {{usuario.comunicacion_fecha}}
      </div>
        
    </div> 
  </div> 
</div>